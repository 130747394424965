import React from "react";
import Navbar from "../../Layout/Navbar";
import Footer from "../../Layout/Footer";

export const Privacy = () => {
  return (
    <div>
      <Navbar />

      <div className="container text-[16px] flex justify-center items-center text-justify w-full pt-16">
        <div className="content sm:w-[80%] w-[95%] py-24">
          <h3 class="text-[40px] font-medium">
            PRIVACY POLICY
            <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
          </h3>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              About this Notice
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              This Privacy Notice provides information on how HERRANDS SOLUTIONS
              LIMITED collects and processes your personal data when you visit
              our website or mobile applications. It sets out what we do with
              your personal data and how we keep it secure and explains the
              rights that you have in relation to your personal data. <br />
              <br /> HERRANDS SOLUTIONS LIMITED is committed to protecting your
              privacy online. This Privacy Policy endeavours to describe to you
              our practices regarding the personal information we collect from
              users on our websites and mobile applications (“Avenues”), as well
              as the services offered through these Avenues. If you have any
              questions about our Privacy Policy, our collection practices, the
              processing of user information, or if you would like to report a
              security violation to us directly, please contact us at{" "}
              <a href="mailto:send@herrands.com">send@herrands.com</a>.
            </p>
          </div>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              What Data We Collect
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              Personal Information: We collect personal information, including
              but not limited to your name, email address, and payment
              information, as necessary to provide our time-sharing services. We
              may collect information about your device and usage of our
              Platform, including IP addresses, browser type, and referral URLs.{" "}
              <br />
              <br /> General Data: The use of our services will automatically
              create information that will be collected. For example, when you
              use our Services, your geographic location, how you use the
              Services, information about the type of device you use, your Open
              Device Identification Number, date/time stamps for your visit,
              your unique device identifier, your browser type, operating
              system, Internet Protocol (IP) address, and domain name are all
              collected. This information is generally used to help us deliver
              the most relevant information to you and administer and improve
              the Site. <br />
              <br /> Log Files: As is true of most websites and mobile
              applications, we gather certain information automatically and
              store it in log files. This information includes but is not
              limited to IP addresses, browser type, Internet service provider
              (ISP), referring/exit pages, operating system, date/time stamp,
              and clickstream data. We use this information to maintain and
              improve the performance of the Services. <br />
              <br /> Analytics: We use analytics services (including, but not
              limited to, Google Analytics) to help analyse how users use the
              Site. Analytics services use Cookies to collect information such
              as how often users visit the Site and we use the information to
              improve our Site and Services. The analytics services' ability to
              use and share information collected by them is restricted by their
              terms of use and privacy policy, which you should refer to for
              more information about how these entities use this information.{" "}
              <br />
              <br /> Location Information: If you have enabled location services
              on your mobile device, we may collect your location information to
              improve the Services we offer. If you do not want this information
              collected, you can disable location services on your device.{" "}
              <br />
              <br /> Cookies: “Cookies” are small pieces of information (text
              files) that a website sends to your computer’s hard drive while
              you are viewing the website. These text files can be used by
              websites to make the user experience more efficient. The law
              states that we can store these cookies on your device if they are
              strictly necessary for the operation of this site. For all other
              types of cookies we need your permission. To that end, this site
              uses different types of cookies. Some cookies are placed by third
              party services that appear on our pages. We and some third parties
              may use both session Cookies (which expire once you close your web
              browser) and persistent Cookies (which stay on your computer until
              you delete them) to provide you with a more personal and
              interactive experience on our Services and to market the Services
              or other products. Marketing cookies are used to track visitors
              across websites. The intention is to display ads that are relevant
              and engaging for the individual user and thereby more valuable for
              publishers and third-party advertisers.
            </p>
          </div>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Use of Your Personal information
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              To use certain features of our Platform, you may need to create an
              We use your information to provide our services, process payments,
              and communicate with you. We may use your information to improve
              our Platform, customise your experience, and send you updates and
              promotional materials. In addition, we may use your personal
              information in the following ways: <br />
              <br />
              <ul>
                <li className="list-disc">
                  Operate, maintain, and improve our site, products, and
                  services;
                </li>
                <li className="list-disc">
                  Respond to comments and questions and provide sender service;
                </li>
                <li className="list-disc">
                  Link or combine user information with other personal
                  information we get from third parties, to help understand your
                  needs and provide you with better service;
                </li>
                <li className="list-disc">
                  Develop, improve, and deliver marketing and advertising for
                  the Services;
                </li>
                <li className="list-disc">
                  {" "}
                  Provide and deliver products and services you request;
                </li>
                <li className="list-disc">
                  {" "}
                  Identify you as a user in our system;
                </li>
              </ul>
              We may also create anonymous data records from your personal
              information by completely excluding information (such as your
              name) that makes the data personally identifiable to you. We use
              this anonymous data to analyse request and usage patterns so that
              we may enhance the content of our Services and improve Site
              functionality. We reserve the right to use anonymous data for any
              purpose and disclose anonymous data to third parties at our sole
              discretion. <br />
              <br /> We may receive testimonials and comments from users who
              have had positive experiences with our Services. We may publish
              such content. When we publish this content, we may identify our
              users by their first and last name. We obtain the user's consent
              prior to posting this information along with the testimonial.
            </p>
          </div>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Disclosure of Your Personal information
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              We disclose your personal information as described below and as
              described elsewhere in this Privacy Policy. <br />
              <br /> Third Parties Designated by You: When you use the Services,
              the personal information you provide will be shared with the third
              parties that you authorise to receive such information. <br />
              <br /> Third Party Service Providers: We may share your personal
              information with third party service providers to: provide you
              with the Services that we offer you through our Services; conduct
              quality assurance testing; to provide technical support; market
              the Services; and/or to provide other services to us.
              <br />
              <br /> Information We Share: We may disclose aggregated
              information about our users and information that does not identify
              any individual without restriction. In addition, we may disclose
              personal information that we collect or you provide:
              <br />
              <br />
              <ul>
                <li className="list-disc">
                  To fulfil the purpose for which you provide it, for any other
                  purpose disclosed by us when you provide the information, or
                  with your consent;
                </li>
                <li className="list-disc">
                  To third parties designated by you;
                </li>
                <li className="list-disc">
                  With our subsidiaries and affiliates;
                </li>
                <li className="list-disc">
                  To third parties to process payments made through the
                  Services;
                </li>

                <li className="list-disc">
                  With contractors, service providers and other third parties we
                  use to support our business;
                </li>
                <li className="list-disc">
                  To a buyer or other successor in the event of a merger,
                  divestiture, restructuring, reorganisation, dissolution or
                  other sale or transfer of some or all of our assets, whether
                  as a going concern or as part of bankruptcy, liquidation or
                  similar proceeding, in which personal information held by us
                  about users is among the assets transferred; and
                </li>
              </ul>
              <br />
              <br />
              Other Disclosures: Regardless of any choices you make regarding
              your Personal information (as described below), Herrands may
              disclose Personal information if it believes in good faith that
              such disclosure is necessary: (i) in connection with any legal
              investigation; (ii) to comply with relevant laws or to respond to
              subpoenas or warrants served on our company; (iii) to protect or{" "}
              <br /> <br /> defend the rights or property of Herrands or users
              of the Services; and/or (iv) to investigate or assist in
              preventing any violation or potential violation of the law, this
              Privacy Policy, or our Terms of Use.
            </p>
          </div>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Third Party Websites
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              Our Site may contain links to third party websites. When you click
              on a link to any other website or location, you will leave our
              Site or Services and go to another site, and another entity may
              collect personal information or anonymous data from you. We have
              no control over, do not review, and are not responsible for, these
              outside websites or their content. Please be aware that the terms
              of this Privacy Policy do not apply to these outside websites or
              content, or to any collection of your personal information after
              you click on links to such outside websites. We encourage you to
              read the privacy policies of every website you visit. The links to
              third party websites or locations are for your convenience and do
              not signify our endorsement of such third parties or their
              products, content or websites.
            </p>
          </div>
          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Your Choices Regarding Information
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              Choices: We offer you choices regarding the collection, use, and
              sharing of your personal information. We may periodically send you
              emails that directly promote the use of our Services. When you
              receive promotional communications from us, you may indicate a
              preference to stop receiving further communications from us and
              you will have the opportunity to “opt-out” by following the
              unsubscribe instructions provided in the email you receive or by
              contacting us directly. Despite your indicated email preferences,
              we may send you service-related communications, including notices
              of any updates to our Terms of Use or Privacy Policy. <br />
              <br /> Cookies: If you decide at any time that you no longer wish
              to accept cookies from our Services for any of the purposes
              described above, then you can instruct your browser, by changing
              its settings, to stop accepting cookies or to prompt you before
              accepting a cookie from the websites you visit. Consult your
              browser’s technical information. If you do not accept cookies,
              however, you may not be able to use all portions of the Services
              or all functionality of the Services. If you have any questions
              about how to disable or modify cookies, please contact us at{" "}
              <a href="send@herrands.com">send@herrands.com</a>.
            </p>
          </div>
          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Your Rights
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              As a user of our websites and mobile applications, you have the
              following rights, any of which you may exercise by contacting us
              at <a href="send@herrands.com">send@herrands.com</a>.
              <ul>
                <li className="list-disc">
                  The right to ask what personal data that we hold about you at
                  any time. Extra requests (particularly repetitive) for data
                  may result in a fee being charged to the user.
                </li>
                <li className="list-disc">
                  The right to ask us to update and correct any out-of-date or
                  incorrect personal data that we hold about you free of charge.
                </li>
                <li className="list-disc">
                  As set out above, the right to opt out of any marketing
                  communications that we may send you .
                </li>
              </ul>
            </p>
          </div>
          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Data Security
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              We have put in place security measures to prevent your personal
              data from being accidentally lost, used or accessed in an
              unauthorised way, altered or disclosed. <br />
              <br /> In addition, we limit access to your personal data to those
              employees, runners, contractors and other third parties who have a
              business need to know. They will only process your personal data
              on our instructions and they are subject to a duty of
              confidentiality. <br />
              <br /> We have put in place procedures to deal with any suspected
              personal data breach and will notify you and any applicable
              regulator of a breach where we are legally required to do so.
            </p>
          </div>
          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Data Retention
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              We will take every reasonable step to ensure that your personal
              data is processed for the minimum period necessary for the
              purposes set out in this Privacy Notice. Your Personal Data may be
              retained in a form that allows for identification only for as long
              as: <br />
              <br />
              <ol type="i">
                <li className="list-disc">
                  We maintain an ongoing relationship with you. This will enable
                  us to improve your experience with us and to ensure that you
                  receive communications from us
                </li>
                <li className="list-disc">
                  Your Personal Data is necessary in connection with the
                  purposes set out in this Privacy Notice and we have a valid
                  legal basis,
                </li>
                <li className="list-disc">
                  The duration of: (i) any applicable limitation period (i.e.,
                  any period during which a person could bring a legal claim
                  against us), and;
                </li>
              </ol>
              We will actively review the personal data we hold and delete it
              securely, or in some cases anonymise it, when there is no longer a
              legal, business or consumer need for it to be retained.
            </p>
          </div>
          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Introduction
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              Welcome to Herrands ("we," "us," or "our"), a company registered
              with the Corporate Affairs Commision with the number RC6927181. We
              are committed to protecting your privacy and ensuring that your
              personal information is handled securely and responsibly. This
              Privacy Policy explains how we collect, use, disclose, and
              safeguard your information when you use our mobile application
              ("App"). <br />
              <br /> By using our App, you consent to the practices described in
              this Privacy Policy. If you do not agree with the terms herein,
              please do not use our App.
            </p>
          </div>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Information We Collect
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              Information You Provide: We may collect information that you
              voluntarily provide when using our App, including but not limited
              to: <br />
              <ul className="weinfo">
                <li className="list-disc">
                  Personal and contact information (e.g., name, email address).
                </li>
                <li className="list-disc">
                  User-generated content (e.g., task descriptions, comments).
                </li>
                <li className="list-disc">
                  Payment information (if applicable).
                </li>
                <li className="list-disc">
                  Communication data (e.g., messages sent through our App).
                </li>
                <li className="list-disc">
                  Information provided for account registration and profile
                  creation.
                </li>
              </ul>
              Automatically Collected Information: When you use our App, we may
              automatically collect certain information, including:
              <ul className="weinfo">
                <li className="list-disc">
                  Device information (e.g., device type, operating system).
                </li>
                <li className="list-disc">
                  App usage data (e.g., interactions, preferences, errors).
                </li>
                <li className="list-disc">
                  Location data (with your consent), including precise and/or
                  approximate geolocation.
                </li>
                <li className="list-disc">Analytics and performance data.</li>
              </ul>
            </p>
          </div>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              How We Use Your Information
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              We use the collected information for various purposes, including:{" "}
              <br />
              <ul className="weinfo">
                <li className="list-disc">
                  Providing and improving our App's services and features.
                </li>
                <li className="list-disc">
                  Facilitating task assignment and communication between users.
                </li>
                <li className="list-disc">
                  Processing payments and managing transactions.
                </li>
                <li className="list-disc">
                  Customizing and personalizing your experience.
                </li>
                <li className="list-disc">
                  Analyzing and enhancing the performance of our App.
                </li>
                <li className="list-disc">
                  Complying with legal requirements and obligations.
                </li>
              </ul>
            </p>
          </div>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Your Choices
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              You have the following rights regarding your personal information:
              <ul className="weinfo">
                <br />
                <li className="list-disc">
                  Access: You can request access to your personal data and
                  receive a copy.
                </li>
                <li className="list-disc">
                  Rectification: You can correct inaccuracies in your personal
                  data.
                </li>
                <li className="list-disc">
                  Deletion: You can request the deletion of your personal data,
                  subject to legal limitations.
                </li>
                <li className="list-disc">
                  Object: You can object to the processing of your personal
                  data.
                </li>
              </ul>
            </p>
          </div>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Security
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              We implement reasonable measures to protect your information from
              unauthorized access, disclosure, alteration, or destruction.
              However, please note that no method of transmission over the
              internet or electronic storage is entirely secure.
            </p>
          </div>
          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Changes to This Privacy Policy
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              We may update this Privacy Policy from time to time. You will be
              notified of any changes either through the App or via email. Your
              continued use of our App following any changes constitutes
              acceptance of those changes.
            </p>
          </div>

          <div class="py-4">
            <h4 class="text-[30px] font-medium">
              Contact Us
              <span class="w-[15px] flex rounded-md bg-[#0066F5] h-[5px]"></span>
            </h4>
            <p>
              If you have questions, concerns, or requests related to this
              Privacy Policy or your personal data, please contact us at: [
              <a href="mailto:send@herrands.com">send@herrands.com</a>]
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
